@font-face {
  font-family: 'Fredoka-Medium';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("./Fonts/Fredoka/static/Fredoka-Medium.ttf") format('truetype');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: 'Fredoka-Regular';
  src: url("./Fonts/Fredoka/static/Fredoka-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Fredoka-Bold';
  src: url("./Fonts/Fredoka/static/Fredoka-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Arial Rounded MT Bold-Regular';
  src: url("./Fonts/arial-rounded-mt-bold/ARLRDBD.ttf") format('truetype');
}

@font-face {
  font-family: "Arimo hebrew Subset-Regular";
  src: url("./Fonts/Arimo/static/Arimo-SemiBold.ttf") format('truetype');
}