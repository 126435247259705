.finds-asset {
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    display: flex;
    flex-direction: column;
    height: 485px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 374px;
}

.finds-asset .image {
    background-image: url("../../../public/Images/buildings.jpg");
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 4px 4px #00000040;
    height: 273px;
    position: relative;
    width: 370px;
}

.finds-asset .text-container {
    align-items: flex-end;
    background-color: #f6f6f6f6;
    border-radius: 0px 0px 10px 10px;
    box-shadow: inset 0px 4px 4px #00000040;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 192px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 350px;
}

.finds-asset .label {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 0px 29px;
    position: relative;
    width: 300px;
}

.finds-asset .label-div {
    color: #000000;
    direction: rtl;
    font-family: "Fredoka-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.finds-asset .info {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    height: 91px;
    justify-content: flex-end;
    padding: 5px;
    position: relative;
    width: 340px;
}

.finds-asset .info-div {
    color: #000000;
    font-family: "Fredoka-Medium";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-right: -1px;
    margin-top: -1px;
    position: relative;
    text-align: right;
    width: fit-content;
    direction: rtl;
}

.finds-asset .bottom {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 56px;
    justify-content: center;
    margin-bottom: -4px;
    position: relative;
    width: 340px;
}

.bottom p {
    color: #000000;
    direction: rtl;
    font-family: "Fredoka-Medium";
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.bottom .button-instance {
    flex: 0 0 auto !important;
}

.bottom .button-instance .text-wrapper {
    font-size: 12px !important;
    font-weight: 500 !important;
    white-space: unset !important;
}