.finds-frame {
    align-items: center;
    background-color: #f6f6f6f6;
    display: flex;
    flex-wrap: wrap;
    gap: 35px 20px;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    padding: 100px 0px 30px 0px;
    position: relative;
    overflow-x: hidden;
}

