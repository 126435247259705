.OptionsList {
    align-items: center;
    background-color: #ffffff;
    display: flex;
    /* gap: 10px; */
    height: 68px;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: 155px;
  }
  
  .OptionsList .text-wrapper-list {
    color: #000000;
    direction: rtl;
    font-family: "Fredoka-Medium", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .OptionsList:hover {
    background-color: #e3e3e3;
  }
  