@media only screen 
  and (max-width: 768px) {
  
  .navbar {
    width: 97vw !important;
  }

  .hamburger {
    position: fixed;
    left: 10px;
    top: 18px;
  }

  .navbar .logo{
    height: 50px !important;
    width: 100px !important;
  }

}

.navbar {
    align-items: center;
    background-color: #f6f6f6;
    box-shadow: 0px 4px 4px #00000040;
    display: flex;
    gap: 20px;
    height: 50px;
    justify-content: flex-end;
    padding: 11px 21px;
    position: fixed;
    width: 97vw;
    z-index: 100;
}
  
  .navbar .icons-container {
    align-items: center;
    display: flex;
    gap: 10px;
    height: 58px;
    justify-content: center;
    padding: 2px 30px;
    position: relative;
  }
  
  .navbar .links-container {
    align-items: center;
    display: flex;
    gap: 50px;
    justify-content: center;
    margin-bottom: -6.5px;
    margin-top: -6.5px;
    padding: 10px;
    position: relative;
    width: 70%;
  }
  
  .navbar .nav-link-instance {
    width: 126px !important;
  }
  
  .navbar .design-component-instance-node {
    left: 14px !important;
  }
  
  .navbar .div-wrapper {
    height: 37px;
    position: relative;
    width: 253px;
  }
  
  .navbar .p {
    color: #000000;
    direction: rtl;
    font-family: "Fredoka-Medium", Helvetica;
    font-size: 32px;
    font-weight: 400;
    left: 11px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -1px;
  }
  
  .navbar .span {
    font-weight: 500;
  }
  
  .navbar .text-wrapper-2 {
    font-family: "Arial Rounded MT Bold-Regular", Helvetica;
  }
  
  .navbar .nav-options-instance {
    flex: 0 0 auto !important;
    margin-bottom: -4px !important;
    margin-top: -4px !important;
  }
  
  .navbar .logo-containter {
    align-items: center;
    display: flex;
    gap: 20px;
    height: 82px;
    justify-content: center;
    margin-bottom: -12px;
    margin-top: -12px;
    position: relative;
    width: 238px;
  }
  
  .navbar .icon-user {
    height: 25px;
    position: relative;
    width: 21.43px;
  }
  
  .navbar .line {
    width: 0; 
    height: 60%; 
    border-left: 1px solid gray;
  }
  
  .navbar .logo {
    height: 64px;
    position: relative;
    width: 137px;
    cursor: pointer;
  }
  