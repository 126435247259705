@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(-10%); /* End slightly left of center */
        opacity: 1;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(10%); /* End slightly right of center */
        opacity: 1;
    }
}


.profile-bubble {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    transition: transform 0.5s ease-out;
    will-change: transform;
    position: relative;
    z-index: 1;
}

.profile-bubble .profile-photo {
    padding-top: 6px;
    position: relative;
    z-index: 1;
}

.profile-bubble .profile-text-name {
    font-family: 'Fredoka-Medium';
    font-size: 30px;
    direction: rtl;
    color: white;
}

.profile-bubble .profile-text {
    font-family: 'Fredoka-Medium';
    font-size: 15px;
    direction: rtl;
    color: white;
}

.profile-bubble .text-bubble {
    position: relative;
    background-color: #f88055;
    border-radius: 18px;
    padding: 10px 15px;
    margin: 20px; /* Adjust as needed */
    max-width: 200px;
    word-wrap: break-word;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

/* Pseudo-element for the tail */
.profile-bubble .text-bubble::after {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 20px; /* Tail width */
    height: 20px; /* Tail height */
    background: #f88055;
    border-radius: 50%; 
}

.profile-bubble.right .text-bubble::after{
    right: -10px; 
    transform: translate(-50%, 50%) rotate(-45deg);
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.2);
    clip-path: polygon(0% 0%, 0% 100%, 100% 0);
}

.profile-bubble.left .text-bubble::after {
    left: -10px; 
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    transform: translate(50%,50%) rotate(45deg);
    clip-path: polygon(0% 0%, 100% 100%, 100% 0%);
}
