.accessibility-menu{
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 500px;
    /* overflow: hidden; */
    position: relative;
    width: fit-content !important;
}

.accessibility-menu .frame {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 18px;
    height: 74px;
    justify-content: flex-end;
    padding: 10px 40px;
    position: relative;
    width: 200px;
}

.accessibility-menu .text-wrapper {
    color: #000000;
    direction: rtl;
    font-family: "Rubik-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    /* position: relative; */
    /* text-align: left; */
    white-space: nowrap;
    width: fit-content;
}
.accessibility-menu .icon-magnifying {
    height: 40px !important;
    margin-bottom: -4.5px;
    margin-top: -4.5px;
    position: relative;
    width: 40px;
}

.accessibility-menu .img {
    height: 40px;
    margin-bottom: -4.5px;
    margin-top: -4.5px;
    /* position: relative; */
    width: 40px;
}
.accessibility-menu .div {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 20px;
    height: 74px;
    justify-content: flex-end;
    padding: 10px 40px;
    /* position: relative; */
    width: 200px;
}

.accessibility-menu .div:hover,
.accessibility-menu .img:hover,
.accessibility-menu .frame:hover,
.accessibility-menu .frame-2:hover{
    background-color: rgb(208, 208, 208);
}
.accessibility-menu .icon-barcode {
    height: 45px;
    /* position: relative; */
    width: 60px;
}
.accessibility-menu .img-2 {
    height: 60px;
    margin-bottom: -3px;
    margin-top: -3px;
    /* position: relative; */
    width: 60px;
}
.accessibility-menu .icon-reverse-contrast {
    height: 56px;
    margin-bottom: -1px;
    margin-top: -1px;
    position: relative;
    width: 56px;
}
.accessibility-menu .frame-2 {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 20px;
    height: 74px;
    justify-content: flex-end;
    padding: 10px 40px;
    position: relative;
    width: 200px;
}
.accessibility-menu .emoji-light {
    height: 63px;
    margin-bottom: -4.5px;
    margin-right: -1.5px;
    margin-top: -4.5px;
    position: relative;
    width: 37.57px;
}