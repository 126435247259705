/* Footer.css */
.footer {
    display: flex;
    justify-content: flex-end; /* Aligns the content to the right */
    padding: 10px 20px; /* Adjust as needed */
    background-color: #f6f6f6; /* Match the navbar background or choose another color */
    box-shadow: 0 -4px 4px #00000040; /* Optional: adds a shadow to the top of the footer */
    z-index: 1000;
    position: relative;
}

.footer-link {
    color: #000; /* Text color */
    text-decoration: none; /* Removes underline from the link */
    font-family: "Fredoka-Medium", Helvetica; /* Adjust font as needed */
    /* Additional styling as needed */
}
