.nav-link , .user{
  position: relative; /* This ensures the dropdown is positioned relative to the nav-link */
  /* Other styles... */
}

.active .nav-optionslist , .nav-optionslist{
  align-items: center;
  background-color: #f6f6f6;
  box-shadow: 0px 4px 4px #00000040;
  display: none; /* Hide by default */
  flex-direction: column;
  position: absolute; /* Positioned absolutely to the nav-link */
  top: 100%; /* Positioned right below the nav-link */
  left: 0; /* Align it to the left edge of the nav-link */
  z-index: 1000; /* Ensures it's on top of other content */
  width: 170px; /* Width can be adjusted as needed */
}

.active .nav-optionslist , .active-user .nav-optionslist{
  display: flex; 
  cursor: pointer;
}

.active-user .nav-optionslist {
  width: 130px;
}

.active-user .OptionsList{
  width: 8vw;
}

.nav-optionslist .frame {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 68px;
  justify-content: center;
  padding: 10px;
  /* width: 100%; Width can be adjusted as needed */
}

/* The negative margins in .OptionsList-instance may not be necessary unless you have a specific reason for them. */
.nav-optionslist .OptionsList-instance { 
  margin-left: -5px !important;
} 
