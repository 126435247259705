@media only screen and (max-width: 768px){
    .main-frame .div-2 {
        right: 0; /* Closer to the edge for smaller screens */
        width: 24vw !important; /* Larger relative width for visibility on small screens */
        top: 15px !important;
        /* Other styles... */
    }

    .main-frame .text-wrapper-8 {
        margin-left: 1vw !important;
    }
}

.main-frame {
    background-image: url("../../../public/Images/homePhoto.jpg");
    /* background-position: 100% 100%; */
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    /* position: relative; */
    width: 100vw;
}

.main-frame .div-2{
    align-items: center;
    right: 10px;
    padding: 30px 10px;
    margin-top: 70px;
    position: absolute;
    top: 155px;
    width: fit-content;
}

.main-frame .div-3{
    align-items: center;
    display: flex;
    gap: 5px;
    letter-spacing: 0;
    height: 15vh;
    padding: 1vw;
    position: relative;
}

.main-frame .text-wrapper-6 {
    color: #000000;
    direction: rtl;
    font-family: "Arimo hebrew Subset-Regular", Helvetica;
    font-size: 2vw;
    font-weight: 400;
    line-height: normal;
    margin-bottom: -1.5px;
    margin-top: -3.5px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.main-frame .icon-dot{
    height: 10px !important;
    position: relative;
    width: 3vw;
}

.main-frame .text-wrapper-7{
    color: #000000;
    font-family: "Arial Rounded MT Bold-Regular";
    font-size: 5vw;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
    margin-left: 1vw;
}

.main-frame .text-wrapper-8 {
    color: #000000;
    direction: rtl;
    font-family: "Arimo Hebrew Subset-Regular";
    font-size: 2.5vw;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    margin-bottom: 40px;
    margin-top: 30px;
    margin-left: 23px;
}

.main-frame .button {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    margin-left: 5vw !important;
    width: fit-content ;
    height: 40px;
}

.main-frame .button .text-wrapper{
    font-size: 2vw;
}

